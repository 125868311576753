<template>
  <div class="vSectionQr__detail section-profile">
    <div
      class="vSectionQr__back"
      @click="getBack"
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 8L10 12L14 16"
          stroke="#6764FF"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      Назад
    </div>
    <ul class="vSectionQr__list">
      <li class="vSectionQr__item">
        Стол {{ codeTable }}
        <a
          :href="tableUrl"
          target="_blank"
          class="vSectionQr__link"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_7064_210959)">
              <path
                d="M10 13.0001L10.953 13.9531V13.9531C12.5151 15.5153 15.0477 15.5153 16.6098 13.9533C16.6099 13.9532 16.6099 13.9532 16.61 13.9531L19.641 10.9221V10.9221C21.4532 9.10976 21.4532 6.17151 19.641 4.35914V4.35914C17.8286 2.54695 14.8904 2.54695 13.078 4.35914L12.191 5.24714"
                stroke="#6764FF"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14 9.99965L13.047 9.04665V9.04665C11.4849 7.48451 8.95229 7.48445 7.39016 9.0465C7.39011 9.04655 7.39006 9.0466 7.39001 9.04665L4.35901 12.0776V12.0776C2.54683 13.89 2.54683 16.8283 4.35901 18.6406H4.35901C6.17139 20.4528 9.10963 20.4528 10.922 18.6406L11.809 17.7526"
                stroke="#6764FF"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_7064_210959">
                <rect
                  width="24"
                  height="24"
                  fill="white"
                />
              </clipPath>
            </defs>
          </svg>

          <span>Ссылка на стол</span>
        </a>
      </li>
    </ul>

    <div
      v-if="qrInvertImg"
      class="vSectionQr__qr"
    >
      <img
        :src="qrInvertImg"
        alt="QR"
      >
      <div
        class="vSectionQr__download"
        @click="downloadQr"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.3643 5.63601C21.8793 9.15101 21.8793 14.849 18.3643 18.364C14.8493 21.879 9.15125 21.879 5.63625 18.364C2.12125 14.849 2.12125 9.15101 5.63625 5.63601C9.15125 2.12101 14.8493 2.12101 18.3643 5.63601Z"
            stroke="#6764FF"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M18.3643 5.63601C21.8793 9.15101 21.8793 14.849 18.3643 18.364C14.8493 21.879 9.15125 21.879 5.63625 18.364C2.12125 14.849 2.12125 9.15101 5.63625 5.63601C9.15125 2.12101 14.8493 2.12101 18.3643 5.63601Z"
            stroke="#6764FF"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8.46387 15.536C10.4169 17.489 13.5819 17.489 15.5349 15.536M11.9999 7V14V7ZM14.0249 11.975L11.9999 14L9.97487 11.975H14.0249Z"
            stroke="#6764FF"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        Скачать
      </div>
    </div>

    <br>

    <div
      v-if="qrImg"
      class="vSectionQr__qr vSectionQr__qr--black"
    >
      <img
        :src="qrImg"
        alt="QR"
      >
      <div
        class="vSectionQr__download"
        @click="downloadQr('qr')"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.3643 5.63601C21.8793 9.15101 21.8793 14.849 18.3643 18.364C14.8493 21.879 9.15125 21.879 5.63625 18.364C2.12125 14.849 2.12125 9.15101 5.63625 5.63601C9.15125 2.12101 14.8493 2.12101 18.3643 5.63601Z"
            stroke="#6764FF"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M18.3643 5.63601C21.8793 9.15101 21.8793 14.849 18.3643 18.364C14.8493 21.879 9.15125 21.879 5.63625 18.364C2.12125 14.849 2.12125 9.15101 5.63625 5.63601C9.15125 2.12101 14.8493 2.12101 18.3643 5.63601Z"
            stroke="#6764FF"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8.46387 15.536C10.4169 17.489 13.5819 17.489 15.5349 15.536M11.9999 7V14V7ZM14.0249 11.975L11.9999 14L9.97487 11.975H14.0249Z"
            stroke="#6764FF"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        Скачать
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'

export default {
  name: 'TableDetail',
  data() {
    return {
      selectedTable: null,
    }
  },
  computed: {
    ...mapGetters('user', ['GET_AUTHENTIFICATED']),
    tableUrl() {
      return this.selectedTable?.url || ''
    },
    qrImg() {
      return this.selectedTable?.qr
    },
    qrInvertImg() {
      return this.selectedTable?.qr_invert
    },
    codeTable() {
      return this.selectedTable?.code
    },
  },
  async created() {
    const id = this.$route.params?.id

    if (!id) {
      this.CHAANGE_ACTIVE_PROFILE_MENU({
        name: 'Столики и QR-коды',
        code: 'qr',
      })
      await this.$router.push('/profile')
      return
    }

    try {
      this.TOGGLE_PRELOADER(true)

      const table = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/handbook/table/${id}`,
        {
          headers: {
            Authorization: 'Bearer ' + this.GET_AUTHENTIFICATED,
          },
        }
      )
      this.selectedTable = table.data?.table

      this.CHAANGE_ACTIVE_PROFILE_MENU({
        name: 'Столики и QR-коды',
        code: 'qr',
      })
    } catch (e) {
      console.error('error', e)
    } finally {
      setTimeout(() => {
        this.TOGGLE_PRELOADER(false)
      }, 500)
    }
  },
  methods: {
    ...mapActions('restaurant', ['FETCH_SELECTED_TABLE']),
    ...mapActions('preloader', ['TOGGLE_PRELOADER']),
    ...mapActions('menu', ['CHAANGE_ACTIVE_PROFILE_MENU']),
    async downloadQr(type) {
      axios({
        url:
          type === 'qr'
            ? this.selectedTable?.qr
            : this.selectedTable?.qr_invert,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        console.log(response)
        let fileURL = window.URL.createObjectURL(new Blob([response.data]))
        let fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute(
          'download',
          'table' + this.selectedTable?.code + '.png'
        )
        document.body.appendChild(fileLink)
        fileLink.click()
      })
    },
    async getBack() {
      const from = this.$route.params?.range_from || ''
      const to = this.$route.params?.range_to || ''
      this.TOGGLE_PRELOADER(true)
      this.CHAANGE_ACTIVE_PROFILE_MENU({
        name: 'Столики и QR-коды',
        code: 'qr',
      })
      await this.$router.push({
        name: 'profile',
        params: { range_from: from, range_to: to },
      })
    },
  },
}
</script>

<style lang="scss">
.vSectionQr {
  @media (max-width: 880px) {
    &::after {
      content: '';
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background: #f8f9fd;
      z-index: -1;
    }
  }
  &__header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 32px;
    max-width: 596px;
    width: 100%;
    @media (max-width: 880px) {
      margin-bottom: 4px;
    }
  }
  &__search {
    padding-top: 12px;
    width: 100%;
    display: flex;
    align-items: center;
    @media (max-width: 880px) {
      flex-wrap: wrap;
    }
    &-wrap {
      width: 100%;
      max-width: 150px;
      margin-right: 12px;
      position: relative;
      @media (max-width: 880px) {
        margin-right: 0;
        width: calc(50% - 8px);
        max-width: none;
        margin-bottom: 12px;
        &:first-child {
          margin-right: 8px;
        }
        &:nth-child(2) {
          margin-left: 8px;
        }
      }
      svg {
        position: absolute;
        left: 8px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  &__btn {
    width: 86px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(223.61deg, #547aff 0%, #413dff 100%);
    border-radius: 6px;
    margin-right: 12px;
    border: none;
    color: #fff;
    cursor: pointer;
    @media (max-width: 880px) {
      margin-right: 0;
      width: 100%;
      margin-bottom: 12px;
    }
    &:disabled {
      background: #d5dbf1;
      cursor: no-drop;
    }
    &--red {
      background: rgba(254, 195, 195, 0.15);
      border: 1px solid #ec4e4e;
      color: #ec4e4e;
      margin-right: 0;
      width: auto;
      flex: auto;
    }
  }
  &__empty {
    color: #80869a;
    font-size: 16px;
  }
  &__input {
    width: 100%;
    border: 1px solid #e6e7eb;
    border-radius: 6px;
    height: 30px;
    padding: 0 8px 0 36px;
    &:focus {
      border: 1px solid #6764ff;
    }
    &::placeholder {
      font-weight: 400;
      font-size: 13px;
      color: #80869a;
    }
  }
  &__list {
    margin-bottom: 24px;
    max-width: 596px;
    width: 100%;
    min-height: auto;

    @media (max-width: 880px) {
      min-height: auto;
      margin-bottom: 0;
      &:not(:first-child) {
        margin-top: 0;
      }
    }
    &-wrap {
      display: grid;
      max-width: 1224px;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      gap: 0px 32px;
      grid-template-areas: '. .';
      @media (max-width: 880px) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        grid-template-areas:
          '.'
          '.';
        gap: 0;
        padding-bottom: 20px;
      }
    }
  }
  &__item {
    margin-bottom: 24px;
    background: #fff;
    border: 1px solid #e6e7eb;
    box-sizing: border-box;
    border-radius: 11px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 22px 24px;
    cursor: pointer;
  }
  &__back {
    display: flex;
    align-items: center;
    cursor: pointer;
    @media (max-width: 880px) {
      margin-bottom: 15px;
    }
  }
  &__code {
    margin-right: auto;
    margin-left: 30px;
    color: #c2c5ce;
    font-size: 12px;
  }
  &__qr {
    border: 1px solid #e6e7eb;
    border-radius: 11px;
    max-width: 596px;
    width: 100%;
    background: #fff;
    padding: 58px 24px 26px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &--black {
      background: #000;
      color: #fff;
    }
    img {
      width: 292px;
      height: 292px;
      margin-bottom: 50px;
    }
  }
  &__download {
    margin-left: auto;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    svg {
      margin-right: 7px;
    }
  }
  &__link {
    color: #010d35;
    display: inline-flex;
    align-items: center;
    &:hover {
      span {
        text-decoration: none;
      }
    }
    span {
      text-decoration: underline;
      margin-left: 5px;
    }
  }
  &__preloader {
    background: rgba(220, 222, 227, 0.9);
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 999999;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #010d35;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    img {
      width: 90px;
      height: 90px;
    }
  }
  .vPagination {
    @media (max-width: 880px) {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding-bottom: 90px;
      padding-top: 10px;
      background: #f8f9fd;
      z-index: 2;
    }
  }
}
</style>
